import React from "react"

export default function PositionDetails({ details }) {
  return (
    <div>
      <div className="container">
        <div className="about-position"> {details?.about} </div>
        {details?.sections.map(section => (
          <div className="position-detail-section">
            <h1> {section.heading} </h1>
            <ul>
              {section.points.map(point => (
                <li> {point} </li>
              ))}
            </ul>
          </div>
        ))}
      </div>
    </div>
  )
}
