import React from "react"
import CareerDetailHero from "./Hero"
import "./careerDetail.scss"
import PositionDetails from "./PositionDetails"
import ContactUsForCandidate from "../shared/Footer/ContactUsForCandidate"

export default function CareerDetail({ details }) {
  return (
    <div>
      <CareerDetailHero details={details} />
      <PositionDetails details={details} />
      <ContactUsForCandidate details={details} />
    </div>
  )
}
