import React from "react"
import Button from "../../shared/Button/Button"

export default function CareerDetailHero({ details }) {
  const handleApplyButton = () => {
    if (typeof window !== "undefined") {
      const targetDiv = document.getElementById("apply-for-job")
      targetDiv.scrollIntoView({ behavior: "smooth" })
    }
  }
  return (
    <div className="career-details-hero-wrapper">
      <div className="container">
        <div className="hero-body">
          <span> IN-HOUSE </span>
          <h1> {details?.positionHeading} </h1>
          <p>{details?.description}</p>
          <h6> {details?.job} </h6>
          <Button
            text="Apply Now"
            variant="fill"
            onClick={handleApplyButton}
            //href="#apply-for-job"
            className="text-capitalize"
          />
        </div>
      </div>
    </div>
  )
}
